import { OnboardRule } from '@core/models';
import * as dashboard from './dashboard.guides';

export { dashboard };

export const ONBOARDING_RULES: OnboardRule[] = [
    {
        displayName: 'Dashboard Onboarding',
        onboardingId: 'dashboard.publisher',
        redirect: ['/'],
        urlMatch: new RegExp(/^\/$/),
        userRole: 'p',
    },
    {
        displayName: 'Dashboard Onboarding',
        onboardingId: 'dashboard.recipient',
        redirect: ['/'],
        urlMatch: new RegExp(/^\/$/),
        userRole: 'r',
    },
    // {
    //   onboardingId: 'products.publisher',
    //   urlMatch: new RegExp(/^\/products\S+offset\S+searchId\S+$/),
    //   userRole: 'p',
    // },
];
