import { GuideStep, Permissions } from '@core/models';

export const publisher: GuideStep[] = [
    {
        heading: 'Welcome to BrandSync',
        description: `Let's take a tour fo the basic features for product information creation, browsing and maintenance. It'll take less than
    a minute.`,
        permissions: () => true,
    },
    {
        heading: 'Products',
        description: `Product page includes Product Catalog that stores all of your product information. Here you can easily browse, filter and
    share your products with your partner companies.`,
        targetSelector: 'a[routerlink="/products"]',
        permissions: () => true,
    },
    {
        heading: 'My Listings',
        description: `My Listings allows you to control what is happening with product information that are shared with your partner
    companies.`,
        targetSelector: 'a[routerlink="/my-listings"]',
        permissions: (userPermissions: Permissions) => {
            return userPermissions.products.readOwn && userPermissions.currentUser.displayMyListings;
        },
    },
    {
        heading: 'Knowledge Base',
        description: `Here you can find all tips and answers concerning BrandSync application.`,
        targetSelector: '.onboarding-tour',
        permissions: () => true,
    },
    {
        heading: 'User menu',
        description: `Here you'll find your profile and your company information.`,
        targetSelector: '.dash-header__user',
        permissions: () => true,
    },
    {
        heading: 'Your Dashboard',
        description: `Dashboard stores summary of most crucial statistics concerning your products and your company relations. You can easily
    track most recent changes here.`,
        targetSelector: '.dashboard__header-text',
        permissions: () => true,
    },
];

export const recipient: GuideStep[] = [
    {
        heading: 'Welcome to BrandSync',
        description: `Let's take a tour fo the basic features for product information browsing, flow and maintenance. It'll take less than a
    minute.`,
        permissions: () => true,
    },
    {
        heading: 'Products',
        description: `Product page includes Product Catalog that stores all of your product information. Here you can easily browse, filter and
    download products shared by your partner companies.`,
        targetSelector: 'a[routerlink="/products"]',
        permissions: () => true,
    },
    {
        heading: 'My Listings',
        description: `My Listings allows you to control what is happening with product information that is shared with you by your partner
    companies. <br><br>Here you can easily track which products are pending your approval.`,
        targetSelector: 'a[routerlink="/my-listings"]',
        permissions: (userPermissions: Permissions) => {
            return userPermissions.products.readOwn && userPermissions.currentUser.displayMyListings;
        },
    },
    {
        heading: 'Knowledge Base',
        description: `Here you can find all tips and answers concerning BrandSync application.`,
        targetSelector: '.onboarding-tour',
        permissions: () => true,
    },
    {
        heading: 'User menu',
        description: `Here you'll find your profile and your company information.`,
        targetSelector: '.dash-header__user',
        permissions: () => true,
    },
    {
        heading: 'Your Dashboard',
        description: `Dashboard stores summary of most crucial statistics concerning your products and your company relations. <br><br>Here you
    can easily track most recent changes and your pending tasks.`,
        targetSelector: '.dashboard__header-text',
        permissions: () => true,
    },
];
