import { prepareActions } from '@app/utils';
import { Action } from '@ngrx/store';

import { LocalUser, OnboardingActiveTour, OnboardingId, OnboardRule } from '@core/models';

export const ActionTypes = prepareActions('[Onboardings]', [
    'STEP_NEXT',
    'STEP_PREV',
    'STEP_REDIRECT',
    'TOUR_CLEAR',
    'TOUR_CLEAR_SKIPPED',
    'TOUR_FINISH',
    'TOUR_NOT_NOW',
    'TOUR_OPEN',
    'TOUR_OPEN_VISITED',
    'TOUR_SKIP',
]);

/**
 * Create 'Next Step' Action
 * @param payload Payload is unnecessary
 */
export class NextStep implements Action {
    type = ActionTypes.STEP_NEXT;
    constructor(public payload?: any) {}
}

/**
 * Create 'Prev Step' Action
 * @param payload Payload is unnecessary
 */
export class PrevStep implements Action {
    type = ActionTypes.STEP_PREV;
    constructor(public payload?: any) {}
}

/**
 * Create 'Open Tour' Action
 * @param payload Contains onboardingId field with onboarding ID and userPermissions field with user permissions object
 */
export class OpenTour implements Action {
    type = ActionTypes.TOUR_OPEN;
    constructor(
        public payload: {
            onboardingId: OnboardingId;
            localUser: LocalUser;
        },
    ) {}
}

/**
 * Create 'Skip Now Tour' Action
 * @param payload Contains onboarding ID
 */
export class SkipNowTour implements Action {
    type = ActionTypes.TOUR_NOT_NOW;
    constructor(public payload: OnboardingId) {}
}

/**
 * Create 'Skip Tour' Action
 * @param payload Contains onboarding ID
 */
export class SkipTour implements Action {
    type = ActionTypes.TOUR_SKIP;
    constructor(public payload: OnboardingActiveTour) {}
}

/**
 * Create 'Finish Tour' Action
 * @param payload Contains onboarding ID
 */
export class FinishTour implements Action {
    type = ActionTypes.TOUR_FINISH;
    constructor(public payload: OnboardingActiveTour) {}
}

/**
 * Create 'Clear Tour' Action
 * @param payload Payload is unnecessary
 */
export class ClearTour implements Action {
    type = ActionTypes.TOUR_CLEAR;
    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Skipped Tours' Action
 * @param payload Payload is unnecessary
 */
export class ClearSkippedTours implements Action {
    type = ActionTypes.TOUR_CLEAR_SKIPPED;
    constructor(public payload?: any) {}
}

/**
 * Create 'Open Visited Tour' Action
 * @param payload Contains onboarding ID
 */
export class OpenVisitedTour implements Action {
    type = ActionTypes.TOUR_OPEN_VISITED;
    constructor(public payload: OnboardRule) {}
}

export type Actions =
    | ClearSkippedTours
    | ClearTour
    | FinishTour
    | NextStep
    | OpenTour
    | OpenVisitedTour
    | PrevStep
    | SkipNowTour
    | SkipTour;
